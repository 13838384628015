import { createSlice, PayloadAction } from 'shared-redux'
import { BokaServiceState } from './types'

export const bokaServiceInitialState: BokaServiceState = {
  steps: [
    { id: 'PRODUCT', label: 'Vilken produkt' },
    { id: 'CONTACT_DETAILS', label: 'Kontaktuppgifter' },
    { id: 'CONFIRMATION', label: 'Bekräftelse' }
  ],
  activeStepIndex: 0,
  services: [],
  communication: ['email'],
  productForm: {
    product: '',
    brand: '',
    serviceDate: null,
    description: ''
  },
  contactForm: {
    name: '',
    phone: '',
    email: '',
    address: '',
    postalCode: '',
    locality: ''
  },
  attachments: []
}

const bokaServiceSlice = createSlice({
  name: 'bokaService',
  initialState: bokaServiceInitialState,
  reducers: {
    nextStep: (state) => {
      if (state.activeStepIndex <= state.steps.length + 1) {
        state.activeStepIndex += 1
      }
    },

    prevStep: (state) => {
      if (state.activeStepIndex > 0) {
        state.activeStepIndex -= 1
      }
    },

    updateServices: (state, { payload: services }: PayloadAction<BokaServiceState['services']>) => {
      state.services = services
    },

    updateAttachments: (
      state,
      { payload: attachments }: PayloadAction<BokaServiceState['attachments']>
    ) => {
      state.attachments = attachments
    },

    updateCommunication: (
      state,
      { payload: communication }: PayloadAction<BokaServiceState['communication']>
    ) => {
      state.communication = communication
    },

    updateProductForm: (
      state,
      { payload: productForm }: PayloadAction<BokaServiceState['productForm']>
    ) => {
      state.productForm = productForm
    },

    updateContactForm: (
      state,
      { payload: contactForm }: PayloadAction<BokaServiceState['contactForm']>
    ) => {
      state.contactForm = contactForm
    }
  }
})

export const {
  updateContactForm,
  updateProductForm,
  updateServices,
  updateAttachments,
  updateCommunication,
  nextStep,
  prevStep
} = bokaServiceSlice.actions

export const bokaServiceReducer = bokaServiceSlice.reducer

export const selectedBokaServiceState = (state: { bokaService: BokaServiceState }) =>
  state.bokaService
