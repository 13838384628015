import React, { FC } from 'react'
import { ListItem, UnorderedList, VStack } from '@chakra-ui/react'
import { GrandLink, GrandText } from 'ui'

export type FooterItem = {
  name: string
  sectionTitle: string
  items: FooterItemItem[]
}

export type FooterItemItem = {
  label?: string
  text?: string
  path?: string
  isExternal?: boolean
}

interface IFooterColumn {
  item: FooterItem
}

const FooterColumn: FC<IFooterColumn> = ({ item }) => {
  return (
    <VStack
      alignItems="flex-start"
      alignSelf="flex-start"
      flexGrow={1}
      spacing={{
        base: 4,
        md: 5
      }}>
      <GrandText as="h2" color="text.light" fontSize="sm" fontWeight="semibold">
        {item.sectionTitle}
      </GrandText>

      <UnorderedList
        listStyleType="none"
        ml={0}
        p={0}
        spacing={{
          base: 3,
          md: 5
        }}>
        {item.items.map((item, index) => (
          <ListItem key={index}>
            <VStack alignItems="flex-start" fontSize="sm" spacing={2}>
              {item.label ? (
                <GrandText fontWeight="semibold" w="auto">
                  {item.label}
                </GrandText>
              ) : null}

              {item.text && item.path ? (
                <GrandLink
                  _focus={{ outline: 'none' }}
                  aria-label={item.text}
                  href={item.path}
                  isExternal={item.isExternal}>
                  {item.text}
                </GrandLink>
              ) : (
                <GrandText w="auto">{item.text}</GrandText>
              )}
            </VStack>
          </ListItem>
        ))}
      </UnorderedList>
    </VStack>
  )
}

export default FooterColumn
